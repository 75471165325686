@import "../variables";

.modal {
  div.modal-content {
    width: 100%;

    div.modal-header {
      h5 {
        &.delete {
          color: $red;
        }
      }
    }

    div.modal-body {
      .label {
        color: rgba(36, 58, 48, 1);
      }

      form {
        .mb-3 {
          label,
          legend {
            color: rgba(36, 58, 48, 1);
          }
        }

        .form-check {
          .form-check-input:checked {
            background-color: $blue;
            border-color: $blue;
          }
        }
      }
    }
  }
}

div.modal-dialog.modal-dialog-centered.modal-md {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
