@import "../variables";

form {
    .mb-3 {
        label {
            color: $primary;

            span.required {
                color: $danger;
            }
        }
    }

    fieldset {
        legend {
            font-size: 1rem;
        }
    }
}

.form-group {
    label {
        color: $blue;
        display: inline-block;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.btn-success {
    color: $white  !important
}