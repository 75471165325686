// Layout
$layout-side-bar-width: 21.428rem;
$layout-top-bar-height: 5rem;

// Animations
$animation-timing: 300ms;

// Bootstrap Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$gray: #A7B0B0;

$blue: #3D88FE !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee220c;
$orange: #ffa700;
$yellow: #ffc107 !default;
$green: #1db100;
$darkgreen: #243A30;
$lightgreen: #92b100;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$lightblue: #F0F2F9;

$gun-powder: #43425d;
$whisper: rgba(249, 249, 250, 0.35);

$primary: #339550;
$primary-semi-transparent: rgba(51, 149, 80, .5);
$primary-bg: #d5f5b8;

$secondary: $whisper;
$tertiary: #eff2f9;
$success: $green;
$warning: #ffa700;
$warning-bg: #f7eac2;
$danger: $red;
$danger-semi-transparent: rgba(238, 34, 12, .2);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
