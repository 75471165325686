/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: Quicksand, sans-serif !important;
  overflow: hidden;
}

.heading {
  margin-bottom: 35px;

  h5 {
    color: rgba(36, 58, 48, 1);
    font-family: inherit;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
  }

  small {
    color: rgba(167, 176, 176, 1);
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }
}

button {
  width: fit-content!important;
}

.btn-success {
  border-radius: 3px !important;
  font-size: 14px !important;
  background-color: #339550 !important;
  border: none !important;
  opacity: 1 !important;
  color: #fff !important;
}

/* Partials */
@import "stylesheets/utils";
@import "stylesheets/partials/form";
@import "stylesheets/partials/table";
@import "stylesheets/partials/modal";
@import "stylesheets/partials/page";
@import "stylesheets/partials/nav";
