@import "../variables";

$td-action-width: 1.714rem;
$td-action-height: 2.625rem;
$stored: lighten($yellow, 40);

table {
    tr {

        th {
            // border: none !important;
            color: $blue;

            &.heading {
                color: var(--bs-gray-500);
            }

            &[sortable] {
                cursor: pointer;
                user-select: none;
                -webkit-user-select: none;

                &.desc,
                &.asc {
                    &::before {
                        content: "";
                        display: block;
                        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC) no-repeat;
                        background-size: 22px;
                        width: 22px;
                        height: 22px;
                        float: left;
                        margin-left: -22px
                    }
                }

                .desc {
                    &::before {
                        transform: rotate(180deg);
                        -ms-transform: rotate(180deg)
                    }
                }

            }

            &.filterable {
                i.fas {
                    color: $whisper;
                    opacity: .5;
                    transition: opacity $animation-timing, color $animation-timing;

                    &.filtered {
                        color: $green;
                        opacity: 1;
                    }
                }
            }
        }

        td {

            &:first-child {
                max-width: 600px !important;
            }

            &.w-250 {
                width: 250px !important;
            }

            &.w-500 {
                width: 500px !important;
            }

            &.w-450 {
                width: 450px !important;
            }

            &.stored {
                background-color: $stored !important;
            }

            &.static {
                background-color: $gray-100 !important;
            }

            &.disabled {}

            &.action {
                padding: .5rem;
                width: $td-action-width !important;
                height: $td-action-height !important;

                div {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    i.fas {
                        cursor: pointer;

                        &.fa-save {
                            color: darken(#fff4d3, 30);
                        }

                        &.fa-plus-circle {
                            color: $green;
                        }

                        &.fa-trash {
                            color: $red;
                        }

                        &.disabled {
                            opacity: .25;
                            cursor: auto;
                            pointer-events: none !important;
                        }
                    }
                }
            }

            &.no-border-top {
                border-top: none !important;
            }
        }
    }
}

.table {
    width: 100% !important;
    table-layout: fixed;
    tbody:not(:first-child) {
        border-top: 1px solid $gray-400 !important;
    }

    td,
    th {
        padding: 0.75rem !important;
    }

    tr {
        th {
            border: none !important;
        }
    }
}

// th[sortable] {
//     cursor: pointer;
//     user-select: none;
//     -webkit-user-select: none
// }

th[sortable].desc:before,
th[sortable].asc:before {
    content: "";
    display: block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC) no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: left;
    margin-left: -22px
}

th[sortable].desc:before {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg)
}
