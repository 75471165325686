$icon-size: 2rem;

div.page {
    margin: 1rem;

    header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        i.fas {
            margin-right: 1rem;
        }

    }

    main {
        position: relative;
    }

}


.bold-title {
    font-weight: 700;
}

.dashboard {
    margin: 5rem;
}

.br-10 {
    border-radius: 10px;
}

.br-20 {
    border-radius: 20px;
}

.c-pointer {
    cursor: pointer;
}