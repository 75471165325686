@import "variables";

hr {
    border-top: 1px solid #ced4da !important;
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        width: 0 !important
    }
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.text-left {
    text-align: left;
}

.text-yellow {
  color: rgb(254, 212, 1);
}

// width

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

/**
 * The Spacer
 */

.spacer {
    background-color: $gray-600;

    &.horizontal {
        height: 1px;

        &.small {
            width: 25%;
        }

        &.medium {
            width: 50%;
        }

        &.large {
            width: 75%;
        }
    }

    &.vertical {
        width: 1px;

        &.small {
            height: 35%;
        }

        &.medium {
            height: 50%;
        }

        &.large {
            height: 90%;
        }
    }
}

/**
 * The Separator
 */

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-style: italic;
    margin: 2.5rem 0;
    color: $gray-600;

    small {
        min-width: 4.5rem;
    }

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ced4da;
    }

    &::before {
        margin-right: .5em;
    }

    &::after {
        margin-left: .5em;
    }

}

/**
 * The Fieldset
 */

fieldset {
    &.bordered {
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem;
        margin: 0 0 1.5em 0 !important;
        padding: 10px 10px;

        legend {
            color: $blue;
            width: inherit;
            padding: 0 .5rem;
        }
    }
}

.chart-tooltip {
    position: absolute;
}

.delete-button-icon {
    position: absolute;
    top: -1px;
    right: 0;
    cursor: pointer;
    margin: 0.2em;
    color: #fff;
    font-size: 15px;
    opacity: 0.2;

    &:hover {
        opacity: 1
    }
}
